import cloneDeep from "clone-deep";
import React, { Component } from "react";
import { SketchPicker } from 'react-color';
import { FaTimes, FaPlusSquare, FaMinusSquare, FaInfoCircle, FaExclamationTriangle } from "react-icons/fa";
import SplitPane from "react-split-pane";
import { toast } from "react-toastify";
import Select from "react-select";
import { t } from "ttag";
import { Colors, DesignData, SpecsMap, ImageFile, Fonts, PPMM, VHHNumberPos } from "../types";
import { parseRoomNumbers } from "../parser";
import {
  fetchGet, fetchPost, getBgUrl, getIconUrl, getLogoUrl, getModelUrl, sketchColors,
  getBackUrl, isMobile, loading, PROD, boxShadow, filterShadow, getIconGroupName, groupBy, parseJson, FontSelectColourStyles, TEXT_SEPARATOR, MODE
} from "../util";
import "./EditPage.css";
import ImageCropperComponent from "./ImageCropper";
import ImageUploaderComponent from "./ImageUploader";
import ModelSVGComponent from "./ModelSVG";
import { NumberInput, SelectProject, Modal } from "./shared";
import { setTactilArea } from "../util";
enum DesignParams {
  name, color, model, project_id, bg_opacity, icon_paths, icon_colors, icon_sizes, icon_texts, icon_vert_positions, icon_hor_positions,
  icon_texts_fonts, icon_texts_colors, icon_texts_sizes, background, option, logo_path, logo_resolution, logo_coords, logo_opacity,room_number_size, roomNumberYPos, brand_color, order_extra, icon_borders
}

type ProjectsSelector = { label: string, value: string, enabled: boolean }[];

type EditPageProps = {
  editing: DesignData,
  projects: ProjectsSelector,
}

type EditPageState = {
  design: DesignData,
  projects: ProjectsSelector,

  onResize?: () => void,
  editPage: number,
  selectedIcon: number,

  sameColorIcon: boolean,
  sameColorText: boolean,

  availableImgs: any,

  customBgFile?: ImageFile,
  customLogoFile?: ImageFile,

  imgToCrop?: string,

  roomParseInvalid?: boolean,
}

const IMG_CROPPER_ID = "imageCropper";

export default class EditPageComponent extends Component<EditPageProps, EditPageState> {
  canvasRef = React.createRef<ModelSVGComponent>();

  constructor(props: EditPageProps) {
    super(props);

    // Make sure it's not null
    props.editing.order_extra = props.editing.order_extra ?? "{}";

    this.state = {
      design: props.editing, projects: props.projects,
      editPage: 0, selectedIcon: 0, availableImgs: {},
      sameColorIcon: false, sameColorText: false,
    };
  }

  registerOnResize = (handler: () => void) => {
    if (this.state.onResize) {
      window.removeEventListener('resize', this.state.onResize);
    }

    this.setState({ onResize: handler }, () => {
      window.addEventListener('resize', this.state.onResize!);
    });
  }

  componentDidMount() {
    fetchGet("/api/images/list/v1")
      .then(res => res.json())
      .then(images => {
        this.setState({ availableImgs: images });
      });
  }

  componentWillUnmount() {
    const bg = this.state.customBgFile;
    if (bg) { URL.revokeObjectURL(bg.preview); }
    const logo = this.state.customLogoFile;
    if (logo) { URL.revokeObjectURL(logo.preview); }
  }

  saveDesign = async (onFinished?: () => void) => {
    const { design: _design, customBgFile, customLogoFile } = this.state;
    setTactilArea(false);
    this.onChange(DesignParams.name, _design.name);
    // Validate that name and project are set
    if (!_design.name?.trim() || !_design.project_id?.trim()) {
      this.setState({ editPage: 0 }, () => {
        const ni = document.getElementById("nameInput");
        if (ni) { (ni as HTMLInputElement).reportValidity(); }
        const gi = document.querySelector("#projectInput input") as HTMLInputElement;
        if (gi) { gi.required = !_design.project_id?.trim(); gi.reportValidity(); }
      });
      setTactilArea(true);
      return;
    }

    if (SpecsMap[_design.model].extra === "vh") {
      const [, isError] = parseRoomNumbers(parseJson(_design.order_extra)?.numbers);
      if (isError || this.state.roomParseInvalid) {
        this.setState({ editPage: 0 }, () => {
          const ni = document.getElementById("roomNumbersInput");
          if (ni) { (ni as HTMLInputElement).reportValidity(); }
        });
        setTactilArea(true);
        return;
      }
    }

    loading(true);

    type DesignExt = DesignData & { thumb?: string };
    let design: DesignExt = cloneDeep(_design);
    
    if(!design.name.includes("{{")){     
      const resid = await  fetchGet(`/api/design/designID`);
      const tx = await resid.text();
      design.name += "{{" + tx;
    }

    // Make sure to limit the size of the arrays to the number of icons
    const iconNumber = SpecsMap[design.model].iconPos.length;
    design.icon_paths = (design.icon_paths || []).slice(0, iconNumber);
    design.icon_colors = (design.icon_colors || []).slice(0, iconNumber);
    design.icon_sizes = (design.icon_sizes || []).slice(0, iconNumber);
    design.icon_texts = (design.icon_texts || []).slice(0, iconNumber);
    design.icon_texts_fonts = (design.icon_texts_fonts || []).slice(0, iconNumber);
    design.icon_texts_colors = (design.icon_texts_colors || []).slice(0, iconNumber);

    // Get the canvas or error
    const canvas = this.canvasRef.current;
    if (!canvas) {
      toast.error(t`Error loading image`);
      setTactilArea(true);
      return;
    }

    // Check if we need to upload files and save the extension in that case
    let uploadBg = !!design.bg_user && !!customBgFile;
    let uploadLogo = !!customLogoFile;

    // Save only the extension for custom images (the name is just the id)
    //
    // XXX: Forcing png because thats what the cropper returns
    //
    if (uploadBg) { design.bg_path = "png"; }
    if (uploadLogo) { design.logo_path = customLogoFile!.ext; }

    try {
      // Save the thumbnail in the request (300px wide)
      design.thumb = await canvas.renderPng(300, false);

      // Send save request
      const res = await fetchPost(`/api/design/${design.id}`, JSON.stringify(design));
      const json = await res.json();

      if (uploadBg || uploadLogo) {
        const formData = new FormData()
        if (uploadBg) { formData.append("bg", customBgFile!.content, `img.${design.bg_path}`); }
        if (uploadLogo) { formData.append("logo", customLogoFile!.content, `img.${design.logo_path}`); }

        const res = await fetchPost(`/api/design/${json.id}/images`, formData);
        if (!res.ok) {
          setTactilArea(true);
          loading(false);
          toast.error(t`Error saving image`);
          console.log("Error:", res.status, res.statusText, await res.text());
          return;
        }
      }

      loading(false);
      toast.success(t`Design saved correctly`);
      setTactilArea(true);
      onFinished?.();

    } catch (e) {
      setTactilArea(true);
      loading(false);
      toast.error(t`Error saving design`);
      console.log("Error:", e);
    };
  }

  onChange = (element: DesignParams, value: any) => {
    this.setState(state => {
      const design = cloneDeep(state.design);
      const selected = state.selectedIcon;
      const spec = SpecsMap[design.model];

      switch (element) {
        case DesignParams.project_id:
        case DesignParams.name:
        case DesignParams.logo_path:
        case DesignParams.logo_resolution:
        case DesignParams.bg_opacity:
        case DesignParams.logo_opacity:
        case DesignParams.room_number_size:  
        case DesignParams.roomNumberYPos:
        case DesignParams.brand_color:
        case DesignParams.order_extra:
          (design as any)[DesignParams[element]] = value;
          break;
        case DesignParams.background:
          const [path, isUser] = value;
          design.bg_path = path;
          design.bg_user = isUser;
          break;
        case DesignParams.logo_coords:
          const [pos, val] = value;

          const coords = design.logo_coords || spec.defaultLogoCoords;
          coords[pos] = val;

          let [x, y, scale] = coords;
          const size = spec.dimensions[0] * scale / 100;

          //////
          {
            const [maxX, maxY] = spec.dimensions;
            const block = [maxX / 2 - maxX / 9, maxY - maxY / 7, maxX / 4.5, maxY / 7];

            // Detect collision between bounding boxes
            if (x < block[0] + block[2] &&
              x + size > block[0] &&
              y < block[1] + block[3] &&
              y + size > block[1]) {

              // collision detected! Calculate side and adjust position,
              // using minkowski sum and checking where the center of it
              // lies with regards to the diagonals
              const cy = (y + size / 2) - (block[1] + block[3] / 2);
              const wy = (size + block[0]) * cy; // (A.width + B.width) * (A.centerY - B.centerY)

              const cx = (x + size / 2) - (block[0] + block[2] / 2);
              const hx = (size + block[1]) * cx; // (A.height + B.height) * (A.centerX - B.centerX)

              if (wy > hx) {
                if (wy > -hx) {
                  // Bottom Collision
                  y = Math.round(block[1] - size);
                  console.log("B");
                } else {
                  // Left Collision
                  x = Math.round(block[0] - size);
                  console.log("L");
                }
              } else {
                if (wy > -hx) {
                  // Right Collision
                  x = Math.round(block[0] + block[2]);
                  console.log("R");
                } else {
                  // Top Collision
                  y = Math.round(block[1] - size);
                  console.log("T");
                }
              }
            }
          }
          //////

          design.logo_coords = [x, y, scale];
          break;

        case DesignParams.icon_paths:
        case DesignParams.icon_colors:
        case DesignParams.icon_texts:
        case DesignParams.icon_texts_colors:
        case DesignParams.icon_texts_sizes:
        case DesignParams.icon_sizes:
        case DesignParams.icon_vert_positions:
        case DesignParams.icon_hor_positions:
        case DesignParams.icon_borders: {
          const d = (design as any), e = DesignParams[element];
          if (d[e] === undefined || d[e] === null) { d[e] = []; }
          d[e][selected] = value;
          break;
        }

        case DesignParams.icon_texts_fonts: {
          const d = (design as any), e = DesignParams[element];
          if (d[e] === undefined || d[e] === null) { d[e] = []; }
          d[e][selected] = value;

          // Asigna la fuente en todas las opciones si no tienes ninguna, para que los hebreos no tengan que cambiarlo cada vez
          spec.iconPos.forEach((_, i) => {
            if (d[e][i] === null || d[e][i] === undefined) { d[e][i] = value; }
          });

          break;
        }

        case DesignParams.model:
          const optionModels = Object.entries(SpecsMap).filter(([m, s]) => s.color === spec.color && s.name === value);

          // Make sure option is available or pick first
          let model = optionModels.find(([m, s]) => s.option === spec.option);
          if (!model) { model = optionModels[0]; }

          // If there are default icons, set them
          if (model[1].defaultIcons) {
            design.icon_paths = [...model[1].defaultIcons];

            // If the previous had the default icons set, remove them
          } else if (spec.defaultIcons) {
            design.icon_paths = []
          }

          // Reset order extra 
          design.order_extra = "{}";

          // Set glass only to the correct value
          if (model[1].option !== "capriccio") {
            design.glass_only = false;
          }

          design.model = model[0];
          break;

        case DesignParams.color:
          design.model = Object.entries(SpecsMap).find(([m, s]) => s.name === spec.name && s.option === spec.option && s.color === value)![0];
          break;
        case DesignParams.option:
          if (value === "onlyGlass") {
            value = "capriccio";
            design.glass_only = true;
          } else {
            design.glass_only = false;
          }

          design.model = Object.entries(SpecsMap).find(([m, s]) => s.name === spec.name && s.color === spec.color && s.option === value)![0];
          break;
        default:
          break;
      }

      return { design: design };
    });
  }

  updateDesign = (p: DesignData) => {
    this.setState({ design: p });
  }

  render() {
    if (!isMobile()) {
      return (
        <SplitPane split="vertical" primary="second" minSize={400} defaultSize={"35%"}
          pane1Style={{ minWidth: 400 }} style={{ position: "relative" }}
          onChange={this.state.onResize}>
          {this.editTabs(false)}
          <ModelSVGComponent ref={this.canvasRef} design={this.state.design} format="svg"
          registerOnResize={this.registerOnResize} updateDesign={this.updateDesign}  />
        </SplitPane>
      );

    } else {
      return <>
        <ModelSVGComponent ref={this.canvasRef} design={this.state.design} format="svg"
        height={window.innerWidth / 1.5} updateDesign={this.updateDesign} />
        {this.editTabs(true)}
      </>
    }
  }

  editTabs = (isMobile: boolean) => {
    const { editPage } = this.state;

    const active = (i: number) => editPage === i ? "active text-primary bg-dark" : "text-light";
    const toggle = (i: number) => ((e: any) => { e.preventDefault(); this.setState({ editPage: i }); });

    return (
      <div className={`text-light m-0 d-flex flex-column ${isMobile ? "" : "h-100"}`}>
        <ul className="editBar nav nav-tabs nav-fill" style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>
          <li className="nav-item">
            <a className={`nav-link ${active(0)}`} onClick={toggle(0)} href="#b">{t`Basic`}</a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${active(1)}`} onClick={toggle(1)} href="#fl">{t`Backgrounds`}</a>
          </li>
          {<li className="nav-item">
            <a className={`nav-link ${active(2)}`} onClick={toggle(2)} href="#i">{t`Icons`}</a>
          </li>}
          <li className="nav-item">
            <a className={`nav-link ${active(3)}`} onClick={toggle(3)} href="#c">{t`Logo`}</a>
          </li>
        </ul>

        <div className="px-4 of-y-scroll of-x-hidden scroller h-100">
          {this.tabContent()}
          <div id="bottom-bar-spacer" />
        </div>
      </div>
    );
  }

  tabContent = () => {
    switch (this.state.editPage) {
      case 0: return this.tabBasicInfo();
      case 1: return this.tabBackground();
      case 2: return this.tabIcons();
      case 3: return this.tabLogo();
      default: return null;
    }
  }

  tabBasicInfo = () => {
    const { design, projects } = this.state;
    const model = SpecsMap[design.model];
    const { availableOptions } = model;

    const projectValue = projects.find(p => p.value === design.project_id);

    const HotelRoomsModalId = "hotelRoomsModal";
    const toggleHotelRoomsModal = () => document.getElementById(HotelRoomsModalId)!.click();
    const orderExtraJson = parseJson(design.order_extra);
    const hotelRoomFont = orderExtraJson?.font ?? "Gotham Light";
    const hotelRoomColor = orderExtraJson?.color ?? "#000000";
    const roomNumberPRefix = orderExtraJson?.prefix ?? "";
    const selectedRoomNumSize = design.room_number_size ?? 10;
    const roomNumberYPos = design.roomNumberYPos ?? 0;
    return <>
      <Modal btnId={HotelRoomsModalId} title={t`Hotel rooms help`} height={450} footer={<div>
        <button type="button" className="btn btn-primary" onClick={toggleHotelRoomsModal}>{t`Close`}</button>
      </div>}>
        <p className="small">{t`This field supports a list of comma separated room numbers and ranges of numbers, with a maximum length of 4 digits per number.`}</p>
        <p className="small">{t`A range of rooms consists of the first and last number in the range, separated by a dash ("-") character, note that both numbers are included in the range.`}</p>
        <p className="small mb-0">{t`Example, to include rooms 101, 103, 105, 106, 107, 108 and 110 we can do:`}</p>
        <code>101, 103, 105-108, 110</code>

        <p className="small mt-3 mb-0">{t`To make sure the numbers are the same length, please put leading zeros as appropiate:`}</p>
        <code>010, 015, 030-050, 130-150</code>
      </Modal>

      <div className="d-flex flex-wrap mt-4 mb-4">
        <div className="mr-5" style={{ width: 450, maxWidth: "90%" }}>
          <div className="form-group w-100">
            <label htmlFor="nameInput">{t`Name`}</label>
            <input name="name" type="text" className="form-control" dir="auto"
              id="nameInput" required placeholder={t`Input the design name`}
              value={design.name.includes("{{") ? design.name.slice(0, -7) : design.name} onChange={(e) => this.onChange(DesignParams.name, e.target.value)} />
          </div>
          <div className="form-group w-100">
            <label htmlFor="projectInput">{t`Project name`}</label>
            <div className="text-dark">
              <SelectProject id="projectInput" options={projects} value={projectValue}
                onSelected={p => this.onChange(DesignParams.project_id, p ? p.value : null)}
                onCreated={p => this.setState(state => {
                  const projects = state.projects.slice();
                  projects.push(p);
                  return { projects };
                })} />
            </div>
          </div>

        </div>
        <div style={{ width: 250 }} className="ml-2 mb-3">
          {t`Options`}
          <div className="form-check mt-2">
            <input className="form-check-input" type="radio" id="desRadInput"
              checked={model.option === "design"} value="design" disabled={!availableOptions[1]}
              onChange={e => this.onChange(DesignParams.option, e.target.value)} />
            <label className="form-check-label" htmlFor="desRadInput">
              Design
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="radio" id="capFullRadioInput"
              checked={model.option === "capriccio" && !design.glass_only} value="capriccio" disabled={!availableOptions[2]}
              onChange={e => this.onChange(DesignParams.option, e.target.value)} />
            <label className="form-check-label" htmlFor="capFullRadioInput">
              {"Capriccio " + t`(glass + casing)`}
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="radio" id="capGlassRadioInput"
              checked={model.option === "capriccio" && design.glass_only} value="onlyGlass" disabled={!availableOptions[2]}
              onChange={e => this.onChange(DesignParams.option, e.target.value)} />
            <label className="form-check-label" htmlFor="capGlassRadioInput">
              {"Capriccio " + t`(glass only)`}
            </label>
          </div>
        </div>
        <ColorList onChange={this.onChange} selected={model.color} disabled={design.glass_only} />
      </div>

      {model.extra === "tlrv" && <div className={MODE === "bes" ? "d-none" : "d-flex flex-wrap mt-4 mb-4"}>
        <div className="custom-control custom-checkbox" style={{ width: 340 }}>
          <input type="checkbox" className="custom-control-input mx-1" id="showFancoilInput"
            defaultChecked={orderExtraJson?.showFancoil ?? MODE === "ing"} onChange={e => {
              const val = parseJson(design.order_extra) ?? {};
              val.showFancoil = e.target.checked;
              this.onChange(DesignParams.order_extra, JSON.stringify(val))
            }} />
          <label className="custom-control-label" htmlFor="showFancoilInput">{t`Show fancoil speeds`}</label>
        </div>
      </div>}

      {model.extra === "vh" && <div className="d-flex flex-wrap mt-4 mb-4">
        <div className="mr-5" style={{ width: 450, maxWidth: "90%" }}>
        <div className="form-group w-100">
            <label htmlFor="roomNumbersInput">{t`Room number prefix`}</label>
            <div className="text-dark">
              <input name="roomNumberPrefix" type="text" className={`form-control ${this.state.roomParseInvalid ? "is-invalid" : ""}`}
                id="roomNumbersPrefixInput" required placeholder={t`Input the room number prefix`} value={orderExtraJson?.prefix ?? ""} onChange={(e) => {
                  const value = e.target.value
                  const val = parseJson(design.order_extra) ?? {};
                  val.prefix = value;
                  this.onChange(DesignParams.order_extra, JSON.stringify(val))
                }}
                 />
            </div>
          </div>


          <div className="form-group w-100">
            <label htmlFor="roomNumbersInput">{t`Room numbers`} <FaInfoCircle className="ml-2" onClick={toggleHotelRoomsModal} /></label>
            <div className="text-dark">
              <input name="roomNumbers" type="text" className={`form-control ${this.state.roomParseInvalid ? "is-invalid" : ""}`}
                id="roomNumbersInput" required placeholder={t`Input the room numbers, see (i) for allowed format`}
                value={orderExtraJson?.numbers ?? ""} onChange={(e) => {

                  const value = e.target.value
                    .replace(/[^-,\s0-9]+/g, "") // Only allow digits, space, comma and dash
                    .replace(/\s+/g, " ") // Collapse multiple spaces into one
                    .replace(/,\s*,/g, ",") //Collapse empty commas, like: "1, ,2" into "1, 2"
                    .replace(/([0-9]{4})[0-9]+/g, "$1"); // Max 4 digits

                  const [parseValues, isError] = parseRoomNumbers(value);
                  console.log(parseValues, isError);

                  const val = parseJson(design.order_extra) ?? {};
                  val.numbers = value;

                  this.setState({ roomParseInvalid: isError })
                  this.onChange(DesignParams.order_extra, JSON.stringify(val))
                }} />
            </div>
          </div>

          <label htmlFor="roomNumberFontSize" className="w-100 text-center">{t`Size` + ": " + selectedRoomNumSize ?? 5}</label>
            <div className="d-flex pb-4">
              <small className="mr-2">{t`Small`}</small>
              <form className="range-field flex-grow-1">
                <input type="range" className="custom-range" min={5} max={15} id="roomNumberFontSize" value={selectedRoomNumSize}
                  onChange={(e) => this.onChange(DesignParams.room_number_size, +e.target.value)} />
              </form>
              <small className="ml-2">{t`Big`}</small>
            </div>

            <label htmlFor="roomNumberYPos" className="w-100 text-center">{t`Vertical position` + ": " + roomNumberYPos ?? 5}</label>
            <div className="d-flex pb-4">
              <small className="mr-2">{t`Down`}</small>
              <form className="range-field flex-grow-1">
                <input type="range" className="custom-range" min={-15} max={15} id="roomNumberYPos" value={roomNumberYPos}
                  onChange={(e) => this.onChange(DesignParams.roomNumberYPos, +e.target.value)} />
              </form>
              <small className="ml-2">{t`Up`}</small>
            </div>


          <label htmlFor="roomTextFontSelect">{t`Room number font`}</label>
          <Select className="text-dark" id="roomTextFontSelect"
            value={{ label: hotelRoomFont, value: hotelRoomFont, enabled: true }}
            isOptionDisabled={o => o.enabled === false}
            styles={FontSelectColourStyles} maxMenuHeight={350}
            options={Object.entries(Fonts).filter(x => !x[1].hidden).map(([key, font]) => ({ label: font.isHeader ? font.font : key.split(":")[0], value: key, enabled: !font.isHeader }))}
            onChange={e => {
              const json = parseJson(design.order_extra) ?? {};
              json.font = (e as any)?.value;
              this.onChange(DesignParams.order_extra, JSON.stringify(json))
            }}
          />
        </div>

        <div style={{ width: 200 }}>
          <div id="roomColorPicker">
            <label htmlFor="roomColorPicker">{t`Room number color`}</label>
            <SketchPicker color={hotelRoomColor} disableAlpha width="180" presetColors={sketchColors}
              onChangeComplete={e => {
                const json = parseJson(design.order_extra) ?? {};
                json.color = e.hex;
                this.onChange(DesignParams.order_extra, JSON.stringify(json))
              }} />
          </div>
        </div>
      </div>}

      <ModelList onChange={this.onChange} selectedModel={model} />
    </>;

  }

  customLogo = (f?: ImageFile | string) => {

    const current = this.state.customLogoFile;
    if (current) { URL.revokeObjectURL(current.preview); }
    let path, file;
    if (typeof f === "string") {
      path = f;
      file = undefined;
    } else {
      path = f ? f.preview : undefined;
      file = f;
    }

    if (path) {
      const img = new Image();
      img.onload = () => {
        const height = img.height;
        const width = img.width;
        this.onChange(DesignParams.logo_resolution, [width, height]);
      }
      img.src = path;
    }

    this.onChange(DesignParams.logo_path, path || null);
    this.setState({ customLogoFile: file });
  }

  customBackground = (f?: ImageFile | string, cb?: () => void) => {
    const current = this.state.customBgFile;
    if (current) { URL.revokeObjectURL(current.preview); }
    let path, file;
    if (typeof f === "string") {
      // Special case for solid colors
      if (f.startsWith("#")) {
        f = f.toLowerCase();

        const specs = SpecsMap[this.state.design.model];
        if ((f === "#ffffff" || f === "#fff") && specs.color === "white") {
          f = undefined;
        } else if ((f === "#000000" || f === "#000") && specs.color === "black") {
          f = undefined;
        }

        this.onChange(DesignParams.background, [f || null, false]);
        this.setState({ customBgFile: undefined, imgToCrop: undefined }, cb);
        return;
      }

      path = f.split(".").pop();
      file = undefined;
    } else {
      path = f ? f.preview : undefined;
      file = f;
    }
    this.onChange(DesignParams.background, [path || null, !!path]);
    this.setState({ customBgFile: file }, cb);
  }

  tabBackground = () => {
    const { availableImgs, customBgFile, design, imgToCrop } = this.state;
    const availableBg = availableImgs.bg;

    const currentBg = customBgFile || (design.bg_path && getBgUrl(design.bg_path, design.bg_user, design.id));

    const removeImg = () => this.setState(({ design }) => {
      // Null because undefined removes them from the object
      // Which means the server doesn't know it has to change them
      (design.bg_path as any) = null;
      (design.bg_user as any) = null;

      return { design, customBgFile: undefined, imgToCrop: undefined };
    });

    const updateBgBlob = (content: Blob | null) => {
      if (!content) { return; }

      this.customBackground({
        content,
        preview: URL.createObjectURL(content),
        ext: "png"
      });
    };

    const [wp, hp] = SpecsMap[design.model].dimensions.map(p => p * PPMM);
    const imageCropper = imgToCrop && <ImageCropperComponent id={IMG_CROPPER_ID} imageSrc={imgToCrop}
      width={wp} height={hp} onCropped={updateBgBlob} onCancel={removeImg}
      onClose={() => this.setState({ imgToCrop: undefined })} />;

    const mainElement = <>
      <section id="selectedBg" className="d-flex flex-wrap mb-4">
        <div className="mt-3 mr-4 ml-2">
          <label className="ml-1" htmlFor="selectedBg">{t`Selected background`}</label>
          <div className="my-2" style={{ border: "1px solid #788", width: 160 }}>
            {
              (typeof currentBg === "string" && currentBg.startsWith("#")) || !currentBg
                ?
                <div style={{ height: 120, width: "100%", background: design.bg_path ?? SpecsMap[design.model].color }} />
                :
                <img src={typeof currentBg === "string" ? currentBg : currentBg.preview} decoding="async" loading="lazy"
                  alt="..." style={{ maxHeight: "100%", maxWidth: "100%" }} />
            }
          </div>

          <small>{t`Opacity:`}</small>
          <NumberInput default={design.bg_opacity} step={5} min={10} max={100} small style={{ width: 160 }}
            onUnitChange={num => this.onChange(DesignParams.bg_opacity, num)} />
        </div>

        <div className="mt-3 mr-4">
          <label className="ml-3 mb-3" htmlFor="bgUpload">{t`User image`}</label>
          <ImageUploaderComponent id="bgUpload" className="m-2"
            minWidth={wp} minHeight={hp} maxSizeMB={10} alwaysUpload
            currentFile={imgToCrop || customBgFile}
            onFileSelected={f => {
              if (!f || typeof f === "string") {
                return this.customBackground(f);
              } else {
                this.customBackground(f, () => {
                  this.setState({ imgToCrop: f.preview }, () => {
                    document.getElementById(IMG_CROPPER_ID)!.click();
                  });
                });
              }
            }} />

          {design.bg_path && !design.bg_path.startsWith("#") &&
            <BrandColorList onChange={this.onChange} selected={design.brand_color} />}
        </div>

        <div className="mt-3 mr-4">
          <label className="ml-3">{t`Solid color`}</label>
          <div className="m-2">
            <SketchPicker color={design.bg_path?.startsWith("#") ? design.bg_path : SpecsMap[design.model].color} disableAlpha
              onChangeComplete={c => this.customBackground(c.hex)} />
          </div>
        </div>
      </section>
    </>;

    return <div className="mt-4">
      {imageCropper}
      {mainElement}

      <label htmlFor="bgList">{t`Included backgrounds`}</label>
      <div id="bgList" className="accordion">
        {Object.keys(availableBg || {}).map((k, i) => (
          <div key={k} className="card mb-2 bg-dark" style={{ boxShadow }}>
            <div className="card-header stretch-contain" id={`heading${i}`}>
              <button className="btn btn-link stretched-link text-light text-decoration-none collapsed"
                type="button" data-toggle="collapse" data-target={`#collapse${i}`} >
                <FaPlusSquare className="plus-collapse" style={{ verticalAlign: -2.5 }} />
                <FaMinusSquare className="minus-collapse" style={{ verticalAlign: -2.5 }} />
                <span className="pl-3">{k}</span>
              </button>
            </div>
            <div id={`collapse${i}`} className="collapse" data-parent="#bgList">
              <div className="card-body d-flex flex-wrap justify-content-around">
                {this.bgList(k, availableBg[k])}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>;
  }

  bgList = (group: string, bgs: string[]) => {
    const size = 120;
    return bgs.map(bg => (
      <div key={bg} className="m-2" style={{
        width: size, cursor: "pointer", boxShadow
      }} onClick={() => { this.onChange(DesignParams.background, [`${group}/${bg}`, false]) }}>
        <img src={getBgUrl(group + "/" + bg)} alt="..." decoding="async" loading="lazy"
          style={{ maxHeight: "100%", maxWidth: "100%" }} />
      </div>
    ));
  }

  stickyObserver: React.FC<{ children: (isSticky: boolean) => JSX.Element }> = (props) => {
    const stickyRef = React.useRef<HTMLDivElement>(null);
    const [isSticky, setIsSticky] = React.useState(false);

    React.useEffect(() => {
      const cachedRef = stickyRef.current;

      if (!isMobile()) {
        const observer = new IntersectionObserver(
          ([e]) => {
            setIsSticky(e.intersectionRatio !== 1);
          },
          { threshold: [1] }
        );

        observer.observe(cachedRef!);

        // unmount
        return function () {
          observer.unobserve(cachedRef!);
        }
      }
    }, []);

    return <div ref={stickyRef} className={`${isSticky ? "sticky-top" : ""} bg-color mx-n4 pt-3`} style={{ boxShadow, top: -1 }}>
      {props.children(isSticky)}
    </div>;
  }

  tabIcons = () => {
    const { design, selectedIcon, availableImgs, sameColorIcon, sameColorText } = this.state;
    const availableIcons = availableImgs.icons;
    const specs = SpecsMap[design.model];

    const border = (m: number) => m === selectedIcon ? "border-primary" : "border-dark";

    const fonts = Object.entries(Fonts);

    const defaultColor = specs.color === "black" ? "white" : "black";
    const selectedPath = design.icon_paths?.[selectedIcon];
    const selectedIconColor = design.icon_colors?.[selectedIcon] ?? defaultColor;
    const selectedText = design.icon_texts?.[selectedIcon] ?? "";
    const [selectedTextL1, selectedTextL2] = selectedText.split(TEXT_SEPARATOR);
    const selectedSize = design.icon_sizes?.[selectedIcon] ?? 5;
    const selectedFontKey = design.icon_texts_fonts?.[selectedIcon] ?? "Gotham";

    const selectedFont = fonts.find(x => selectedFontKey === x[0]);
    const selectedTextColor = design.icon_texts_colors?.[selectedIcon] ?? defaultColor;
    const selectedTextSize = design.icon_texts_sizes?.[selectedIcon] ?? 5;

    const selectedVPos = design.icon_vert_positions?.[selectedIcon] ?? 8;
    const selectedHPos = design.icon_hor_positions?.[selectedIcon] ?? 8;

    const selectedBorder = design.icon_borders?.[selectedIcon] ?? specs.defaultIconBorders?.[selectedIcon];

    const StickyObserver = this.stickyObserver;


    return <>
      <StickyObserver>
        {(isSticky) => <>
          <label className="ml-4" htmlFor="iconSelect" style={{ display: "inline-block" }}>{t`Selected icons`}</label>
          {specs.iconExplanations && <label className="ml-5 small" htmlFor="iconSelect" style={{ display: "inline-block" }}>
            <FaExclamationTriangle className="mr-2" style={{ verticalAlign: -2.5 }} />
            {t`The button functions for this model can't be modified, make sure the icons selected represent their stated functions`}
          </label>}
          <div id="iconSelect" className={`d-flex flex-wrap justify-content-around ${isSticky ? "pb-1" : "pb-3"}`}
            style={{ maxWidth: "90%" }}>
            {
              specs.iconPos.map((_, i) => {
                const path = design.icon_paths[i];
                let img;
                if (path) {
                  img = <>
                    <button className="btn btn-danger btn-circle-sm" title={t`Remove icon`}
                      style={{ position: "absolute", top: -4, right: -4, padding: 3.5 }}
                      onClick={() => this.setState({ selectedIcon: i }, () => {
                        this.onChange(DesignParams.icon_paths, null);
                      })}>
                      <FaTimes color="white" />
                    </button>
                    {/* TODO: Change this to a hook and load the image through a side
                 effect to be able to change the colors like in ModelSVG.tsx:418 */}
                    <img src={getIconUrl(path)} alt="..." style={{
                      maxWidth: "90%", maxHeight: "90%", margin: "auto"
                    }} decoding="async" loading="lazy" />
                  </>;
                }

                const onHover = (i?: number) => () => {
                  const svg = this.canvasRef.current;
                  if (svg) { svg.hoverIcon(i); }
                };

                const iconSize = 60;
                return <div key={i}>
                  <div className={`mx-auto my-1 d-flex stretch-contain ${border(i)}`}
                    style={{ width: iconSize, height: iconSize, cursor: "pointer" }}
                    onClick={() => this.setState({ selectedIcon: i })}
                    onMouseEnter={onHover(i)} onMouseLeave={onHover()}>
                    {img}
                  </div>

                  {specs.iconExplanations?.[i] && <p className="small mb-0 text-center">{specs.iconExplanations?.[i]()}</p>}
                </div>;
              })
            }
          </div>
        </>}
      </StickyObserver>

      <div className="d-flex flex-wrap">
        <div className="d-flex flex-wrap">
          <div className="mr-4 py-3" style={{ width: 340 }}>
            <h4>{t`Icon options`}</h4>

            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input mx-1" id="iconBorderCheck" checked={selectedBorder ?? false} onChange={e => this.onChange(DesignParams.icon_borders, !selectedBorder)} />
              <label className="custom-control-label" htmlFor="iconBorderCheck">{t`Icon border`}</label>
            </div>

            <label htmlFor="iconSizeRange" className="w-100 text-center">{t`Size` + ": " + selectedSize}</label>
            <div className="d-flex pb-4">
              <small className="mr-2">{t`Small`}</small>
              <form className="range-field flex-grow-1">
                <input type="range" className="custom-range" min={1} max={specs.extra === "tlrv" ? 7 : 9} id="iconSizeRange" value={selectedSize}
                  onChange={(e) => this.onChange(DesignParams.icon_sizes, +e.target.value)} />
              </form>
              <small className="ml-2">{t`Big`}</small>
            </div>

            <label htmlFor="iconVPosRange" className="w-100 text-center">{t`Vertical position` + ": " + (selectedVPos - 8)}</label>
            <div className="d-flex pb-4">
              <small className="mr-2">{t`Up`}</small>
              <form className="range-field flex-grow-1">
                <input type="range" className="custom-range" min="0" max="16" id="iconVPosRange" value={selectedVPos}
                  onChange={(e) => this.onChange(DesignParams.icon_vert_positions, +e.target.value)} />
              </form>
              <small className="ml-2">{t`Down`}</small>
            </div>

            <label htmlFor="iconHPosRange" className="w-100 text-center">{t`Horizontal position` + ": " + (selectedHPos - 8)}</label>
            <div className="d-flex pb-4">
              <small className="mr-2">{t`Left`}</small>
              <form className="range-field flex-grow-1">
                <input type="range" className="custom-range" min="0" max="16" id="iconHPosRange" value={selectedHPos}
                  onChange={(e) => this.onChange(DesignParams.icon_hor_positions, +e.target.value)} />
              </form>
              <small className="ml-2">{t`Right`}</small>
            </div>
          </div>

          <div className="my-4" style={{ width: 200 }}>
            <div id="iconColorPicker">
              <label htmlFor="iconColorPicker">{t`Icon color`}</label>
              <SketchPicker color={selectedIconColor} disableAlpha width="180" presetColors={sketchColors}
                onChangeComplete={c => this.onChange(DesignParams.icon_colors, c.hex)} />

              <input id="sameColorIconCheck" type="checkbox" className="m-2" checked={sameColorIcon} onChange={e => this.setState({ sameColorIcon: !this.state.sameColorIcon }, () => {
                const { design, sameColorIcon, selectedIcon } = this.state;
                if (sameColorIcon) {
                  const selected_color = design.icon_colors[selectedIcon];
                  SpecsMap[design.model].iconPos.forEach((v, i) => { design.icon_colors[i] = selected_color; });
                  this.setState({ design });
                }
              })} />
              <label className="small" htmlFor="sameColorIconCheck">{t`Same color for all`}</label>
            </div>
          </div>
        </div>

        <div style={{ width: 40, height: 1 }} />

        <div className="d-flex flex-wrap">
          <div className="mr-4 py-3" style={{ width: 340 }}>
            <h4>{t`Label options`}</h4>
            <div className="pt-1 pb-3">
              <label htmlFor="iconTextSelect1">{t`Text`}</label>
              <input type="text" className="form-control" id="iconTextSelect1" dir={selectedFont?.[1].rtl ? "rtl" : "ltr"}
                placeholder={(n => t`Icon text line ${n}`)(1)} value={selectedTextL1 ?? ""} maxLength={20}
                onChange={(e) => {
                  const text = (e.target.value ?? "") + TEXT_SEPARATOR + (selectedTextL2 ?? "");
                  this.onChange(DesignParams.icon_texts, text);
                }} />
              <input type="text" className="form-control mt-1" id="iconTextSelect2" dir={selectedFont?.[1].rtl ? "rtl" : "ltr"}
                placeholder={(n => t`Icon text line ${n}`)(2)} value={selectedTextL2 ?? ""} maxLength={20}
                onChange={(e) => {
                  const text = (selectedTextL1 ?? "") + TEXT_SEPARATOR + (e.target.value ?? "");
                  this.onChange(DesignParams.icon_texts, text);
                }} />
            </div>

            <div className="pb-3">
              <label htmlFor="iconTextFontSelect">{t`Font`}</label>
              <Select className="text-dark" id="iconTextFontSelect"
                value={{ label: selectedFontKey.split(":")[0], value: selectedFontKey, enabled: true }}
                isOptionDisabled={o => o.enabled === false}
                styles={FontSelectColourStyles} maxMenuHeight={350}
                options={fonts.filter(x => !x[1].hidden).map(([key, font]) => ({ label: font.isHeader ? font.font : key.split(":")[0], value: key, enabled: !font.isHeader }))}
                onChange={e => this.onChange(DesignParams.icon_texts_fonts, (e as any)?.value)}
              />
            </div>

            <label htmlFor="textSizeRange" className="w-100 text-center">{t`Size`}</label>
            <div className="d-flex">
              <small className="mr-2">{t`Small`}</small>
              <form className="range-field flex-grow-1">
                <input type="range" className="custom-range" min="1" max="9" id="textSizeRange" value={selectedTextSize}
                  onChange={(e) => this.onChange(DesignParams.icon_texts_sizes, +e.target.value)} />
              </form>
              <small className="ml-2">{t`Big`}</small>
            </div>
          </div>

          <div className="my-4" style={{ width: 200 }}>
            <div id="textColorPicker">
              <label htmlFor="textColorPicker">{t`Text color`}</label>
              <SketchPicker color={selectedTextColor} disableAlpha width="180" presetColors={sketchColors}
                onChangeComplete={c => this.onChange(DesignParams.icon_texts_colors, c.hex)} />

              <input id="sameColorTextCheck" type="checkbox" className="m-2" checked={sameColorText} onChange={e => this.setState({ sameColorText: !this.state.sameColorText }, () => {
                const { design, sameColorText, selectedIcon } = this.state;
                if (sameColorText) {
                  const selected_color = design.icon_texts_colors[selectedIcon];
                  SpecsMap[design.model].iconPos.forEach((v, i) => { design.icon_texts_colors[i] = selected_color; });
                  this.setState({ design });
                }
              })} />
              <label className="small" htmlFor="sameColorTextCheck">{t`Same color for all`}</label>
            </div>
          </div>
        </div>
      </div>

      <label htmlFor="iconList">{t`Available icons`}</label>
      <div id="iconList" className="accordion">
        {Object.keys(availableIcons || {}).map((k, i) => (
          <div key={k} className="card mb-2 bg-dark" style={{ boxShadow }}>
            <div className="card-header stretch-contain" id={`heading${i}`}>
              <button className="btn btn-link stretched-link text-light text-decoration-none collapsed"
                type="button" data-toggle="collapse" data-target={`#collapse${i}`}>
                <FaPlusSquare className="plus-collapse" style={{ verticalAlign: -2.5 }} />
                <FaMinusSquare className="minus-collapse" style={{ verticalAlign: -2.5 }} />
                <span className="pl-3">{getIconGroupName(k)}</span>
              </button>
            </div>
            <div id={`collapse${i}`} className="collapse" data-parent="#iconList">
              <div className="card-body d-flex flex-wrap justify-content-around" >
                {this.iconList(k, availableImgs.icons_version, availableIcons[k], selectedPath)}
              </div>
            </div>
          </div>
        ))}

        <div className="mt-4 text-center">
          {t`If the available icons are not appropiate for your use case, you can submit new vector icons (.SVG, .AI, .EPS) at the following form:`}
          <br />
          <a href="https://forms.gle/k29zz9k4dZy9EL79A">https://forms.gle/k29zz9k4dZy9EL79A</a>
        </div>
      </div>
    </>
  }
  iconList = (group: string, version: string, icons: string[], selected?: string) => {
    const selectedStyle = (icon: string) =>
      (selected && (icon === `${selected}.svg`)) ? { backgroundColor: "rgba(0,0,0,0.2)", borderRadius: 5 } : {};

    return icons.map(icon => {
      const fullIcon = `${version}:${group}/${icon}`;

      return <div key={icon} className="m-2 d-flex" style={{
        width: 80, height: 80, cursor: "pointer", ...selectedStyle(fullIcon)
      }} onClick={() => this.onChange(DesignParams.icon_paths, fullIcon.replace(".svg", ""))}>
        <img src={getIconUrl(fullIcon)} alt="..." height="100%" style={{
          filter: filterShadow, maxWidth: "90%", maxHeight: "90%", margin: "auto"
        }} decoding="async" loading="lazy" />
      </div>
    });
  }

  tabLogo = () => {
    const { customLogoFile, design } = this.state;
    const specs = SpecsMap[design.model];
    const logoPos = design.logo_coords || specs.defaultLogoCoords;
    const currentLogo = customLogoFile || (design.logo_path && getLogoUrl(design.logo_path, design.id));

    const size = specs.dimensions[0] * logoPos[2] / 100;
    const maxX = specs.dimensions[0] - 2 * size / 3;
    const maxY = specs.dimensions[1] - 2 * size / 3;

    return <>
      <ImageUploaderComponent className="mt-4" maxSizeMB={3}
        currentFile={currentLogo} onFileSelected={this.customLogo}>

        <div style={{ display: currentLogo ? "" : "none", width: 160 }}>
          <small className="mb-2">{t`X:`}</small><NumberInput default={logoPos[0]} step={1} small min={-size / 3} max={maxX}
            onUnitChange={(num) => this.onChange(DesignParams.logo_coords, [0, num])} />
          <small className="mb-2">{t`Y:`}</small><NumberInput default={logoPos[1]} step={1} small min={-size / 3} max={maxY}
            onUnitChange={(num) => this.onChange(DesignParams.logo_coords, [1, num])} />
          <small>{t`Scale:`}</small><NumberInput default={logoPos[2]} step={1} min={10} max={80} small
            onUnitChange={(num) => this.onChange(DesignParams.logo_coords, [2, num])} />
          <small>{t`Opacity:`}</small>
          <NumberInput default={design.logo_opacity} step={5} min={10} max={100} small
            onUnitChange={num => this.onChange(DesignParams.logo_opacity, num)} />
        </div>
      </ImageUploaderComponent>

      {!PROD && <code>{JSON.stringify(design, undefined, 2)}</code>}
    </>
  }
}

function ColorList(props: any) {
  const { onChange, selected, disabled } = props;

  const border = (m: string) => m === selected && !disabled ? "border-primary" : "border-invisible";

  return (
    <div className="form-group" style={{ width: 210, opacity: disabled ? 0.4 : 1 }}>
      <label htmlFor="colorSelect" className="mb-2">{t`Back casing color`}</label>
      <div id="colorSelect" className="d-flex flex-wrap" >
        {
          Colors.map(m => (
            <div key={m} className={`mr-2 ${border(m)}`}
              style={{ width: 80, height: 80, cursor: "pointer" }}
              onClick={() => disabled || onChange(DesignParams.color, m)} >
              <div style={{ padding: 4 }}>
                <img src={getBackUrl(m)} alt="..." style={{ width: "100%", height: "100%", borderRadius: 4 }} decoding="async" loading="lazy" />
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

function BrandColorList(props: any) {
  const { onChange, selected } = props;

  const border = (m: string | null) => m === selected ? "border-primary" : "border-invisible";

  return (
    <div className="form-group ml-3 mt-3" style={{ width: 260 }}>
      <label htmlFor="colorSelect" className="mb-2">{t`Watermark color`}</label>
      <div id="colorSelect" className="d-flex flex-wrap" >
        {
          [[t`Default`, null], [t`Dark`, "black"], [t`Light`, "white"]].map(([name, value]) => (
            <div key={value} className={`mr-2 p-1 ${border(value)}`}
              style={{ cursor: "pointer" }}
              onClick={() => onChange(DesignParams.brand_color, value)} >
              <div style={{ padding: 4 }}>
                {name}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

function ModelList(props: any) {
  const { onChange, selectedModel } = props;
  const { color, option, name } = selectedModel;

  const border = (m: string) => m === name ? "border-primary" : "border-invisible";
  const opacity = (m: string) => m === name ? 0.8 : 0.4;
  const size = 130;

  return (
    <div className="form-group">
      <label htmlFor="modelSelect">{t`Model`}</label>
      <div id="modelSelect" className="d-flex flex-wrap" >
        {
          Object.values(
            groupBy(
              Object.entries(SpecsMap)
                .filter(([m, s]) => s.color === color), // Get only the ones with same color
              ([m, s]) => s.name // Group them by product name
            )
          ).map(x => {
            // Pick the same option as selected or the first one if not available
            let m = x!.filter(([m, s]) => s.option === option)[0];
            if (!m) { m = x![0]; }

            const { code, name } = m[1];

            return <div key={code} className={`${border(name)} mb-3 stretch-contain`}
              style={{ width: size, height: size + 40, cursor: "pointer" }}
              onClick={() => onChange(DesignParams.model, name)}>

              <div className="d-flex" style={{ width: "100%", height: "calc(100% - 45px)" }}>
                <img src={getModelUrl(name, color)} alt="..." title={name}
                  width="60%" className="mb-1" decoding="async" loading="lazy"
                  style={{ borderRadius: 5, border: "1px solid gray", margin: "auto", boxShadow }} />
              </div>

              <div style={{
                width: "100%", height: 25, position: "absolute", bottom: 20,
                opacity: opacity(name), textAlign: "center", fontWeight: "bold",
              }}> {name} </div>

              <div style={{
                width: "100%", height: 20, position: "absolute", bottom: 0, fontSize: "80%",
                opacity: opacity(name), textAlign: "center", fontWeight: "lighter",
              }}> {code} </div>
            </div>;
          })
        }
      </div>
    </div>
  );
}

