import { t } from "ttag";
import { MODE } from "./util";

export type Partial<T> = { [P in keyof T]?: T[P]; };

export type ImageFile = { content: Blob, ext: string, preview: string };

export enum Errors {
  register_validation,
  login_validation,
  user_already_exist,
  wrong_user_pass,
  authentication_error,
  design_not_found,
  user_not_found,
  signups_disabled
}

export type Rank = "user" | "admin-validate" | "admin-printing";
export type ProjectStatus = "created" | "waiting-validate" | "waiting-printing" | "done";

export interface UserData {
  id: string,
  email: string,
  name: string,
  rank: Rank,
}

export interface ProjectData {
  id: string,
  user_id: string,

  name: string,
  status: ProjectStatus,
  order_code: string,

  designs: DesignData[],

  created_at?: string,
  updated_at?: string,
}

export interface DesignData {
  id: string,
  project_id: string,
  name: string,
  model: string,
  glass_only: boolean,
  bg_path?: string,
  bg_user?: boolean,
  bg_opacity: number,
  logo_path?: string,
  logo_resolution?: Coords2,
  logo_coords?: Coords3,
  logo_opacity: number,
  room_number_size: number,
  roomNumberYPos: number,
  brand_color?: string,
  icon_paths: string[],
  icon_colors: string[],
  icon_sizes: number[],
  icon_texts: string[],
  icon_texts_fonts: string[],
  icon_texts_colors: string[],
  icon_texts_sizes: number[],

  icon_vert_positions: number[],
  icon_hor_positions: number[],
  icon_borders: boolean[],

  created_at?: string,
  updated_at?: string,

  order_quantity?: number,
  order_extra?: string,

  // Available only for the admin page
  user_id?: string,
  user_name?: string,
  user_email?: string,
  project_name?: string,

  order_code?: string,
  order_status?: string,
}

type FontsType = { [key: string]: { isHeader: boolean, font: string, weight: number, url: string, hidden?: boolean, rtl?: boolean } };

// Some fonts don't work with arabic text, lookupType 3 and 7 not implemented: Error: "lookupType: 7 - substFormat: 1 is not yet supported"
// Those fonts are disabled
// IMPORTANT: Map keys CANNOT be changed! It would break all existing designs
export const Fonts: FontsType = {
  "General": { isHeader: true, font: t`General`, weight: 0, url: "" },
  "Arial": { isHeader: false, font: "Arial", weight: 400, url: "/fonts/Arial.ttf" },
  "Calibri": { isHeader: false, font: "Calibri", weight: 400, url: "/fonts/Calibri.ttf" },
  "Calibri Light": { isHeader: false, font: "Calibri Light", weight: 400, url: "/fonts/CalibriLight.ttf" },
  "Courier New": { isHeader: false, font: "Courier New", weight: 400, url: "/fonts/CourierNew.ttf" },
  "Garamond": { isHeader: false, font: "Garamond", weight: 400, url: "/fonts/Garamond-Regular.ttf" },
  "Gotham Light": { isHeader: false, font: "Gotham", weight: 100, url: "/fonts/Gotham-Light.ttf" },
  "Gotham": { isHeader: false, font: "Gotham", weight: 400, url: "/fonts/Gotham-Medium.ttf" },
  "Helvetica Narrow": { isHeader: false, font: "Helvetica Narrow", weight: 400, url: "/fonts/HelveticaNarrow.ttf" },

  "Arabic": { isHeader: true, font: t`Arabic`, weight: 0, url: "" },
  "Almarai": { isHeader: false, font: "Almarai", weight: 500, url: "/fonts/Almarai-Regular.ttf" },
  "Amiri": { hidden: true, isHeader: false, font: "Amiri", weight: 500, url: "/fonts/Amiri-Regular.ttf" },
  "Arabic Typesetting": { isHeader: false, font: "Arabic Typesetting", weight: 500, url: "/fonts/ArabicTypesetting.ttf" },
  "Arial:2": { isHeader: false, font: "Arial", weight: 400, url: "/fonts/Arial.ttf" },
  "Cairo": { isHeader: false, font: "Cairo", weight: 500, url: "/fonts/Cairo-Regular.ttf" },
  "Courier New:2": { hidden: true, isHeader: false, font: "Courier New", weight: 400, url: "/fonts/CourierNew.ttf" },
  "Calibri:2": { hidden: true, isHeader: false, font: "Calibri", weight: 400, url: "/fonts/Calibri.ttf" },
  "Calibri Light:2": { hidden: true, isHeader: false, font: "Calibri Light", weight: 400, url: "/fonts/CalibriLight.ttf" },
  "Mada": { hidden: true, isHeader: false, font: "Mada", weight: 500, url: "/fonts/Mada-Regular.ttf" },
  "Lateef": { isHeader: false, font: "Lateef", weight: 500, url: "/fonts/Lateef.ttf" },

  "Hebrew": { isHeader: true, font: t`Hebrew`, weight: 0, url: "", rtl: true },
  "Alef": { isHeader: false, font: "Alef", weight: 400, url: "/fonts/Alef.ttf", rtl: true },
  "Asakim Bold": { isHeader: false, font: "Asakim Bold", weight: 700, url: "/fonts/AsakimBold.ttf", rtl: true },
  "Assistant": { isHeader: false, font: "Assistant", weight: 400, url: "/fonts/Assistant.ttf", rtl: true },
  "DavidLibre": { isHeader: false, font: "DavidLibre", weight: 400, url: "/fonts/DavidLibre.ttf", rtl: true },
  "Felix007": { isHeader: false, font: "Felix007", weight: 500, url: "/fonts/Felix007.ttf", rtl: true },

  "Japanese": { isHeader: true, font: t`Japanese`, weight: 0, url: "" },
  "Meiryo": { isHeader: false, font: "Meiryo", weight: 400, url: "/fonts/Meiryo.ttf" },
  "Meiryo UI": { isHeader: false, font: "Meiryo UI", weight: 400, url: "/fonts/MeiryoUI.ttf" },
  "Yu Gothic": { isHeader: false, font: "Yu Gothic", weight: 400, url: "/fonts/YuGothic.ttf" },
}

export const getModelNameGlassOnly = (model: string, glass_only: boolean) => {
  if (!glass_only) return model;
  const specs = SpecsMap[model];

  const sq = specs.dimensions[0] === specs.dimensions[1];
  return sq ? "GL310000" : "GL320000";
};

export type Coords2 = [number, number]; // X, Y
export type Coords3 = [number, number, number]; // X, Y, Percent of width (1-100)
export type Coords4 = [number, number, number, number]; // X, Y, W, H

export interface ModelSpecs {
  dimensions: Coords2,
  iconPos: Coords2[],
  iconSize: number,
  defaultLogoCoords: Coords3,
  defaultIcons?: string[],
  iconExplanations?: (() => string)[],
  defaultIconBorders?: boolean[],
  extra?: "trmd" | "tl" | "tlv" | "vh" | "vhd" | "tlrv",
  noLeds?: boolean,
  centerTop?: boolean,
}

export interface PartialModelSpecs {
  dimensions: Coords2,
  iconSize: number,
  defaultLogoCoords: Coords3
  noLeds?: boolean,
  centerTop?: boolean,
}

export const PPI = 300;
export const PPMM = PPI / 25.4;

const DIM_MARGIN = 2; // 2 mm, one on each side

/**
 * MODELO SQ
 * Dimensiones: 88mm x 88mm
 * Dimensiones con sangrado 1mm: 90mm x 90mm
 * PPI: 144 - 5.669291 pixels/mm
 *
 * Resolución 510 x 510
 */
export const SqLength = 88 + DIM_MARGIN;
const SqLogoCoords: Coords3 = [0.4 * SqLength, 4, 20];

const SqH = SqLength / 2; // Half size
const SqM1 = 25; // Margin
const SqM2 = SqLength - SqM1; // Size - margin


const partialSQSpecs: PartialModelSpecs = {
  dimensions: [SqLength, SqLength],
  defaultLogoCoords: SqLogoCoords,
  iconSize: 12,
}

const Sq2Specs: ModelSpecs = {
  ...partialSQSpecs, // Ponerlo al principio para que sea posible sobrescribir
  iconPos: [
    [SqM1, SqH],
    [SqM2, SqH],
  ]
}

const Sq4Specs: ModelSpecs = {
  ...partialSQSpecs,
  iconPos: [
    [SqM1, SqM1],
    [SqM2, SqM1],
    [SqM1, SqM2],
    [SqM2, SqM2],
  ]
}

const Sq6Specs: ModelSpecs = {
  ...partialSQSpecs,
  iconPos: [
    [SqM1, SqM1],
    [SqM2, SqM1],
    [SqM1, SqH],
    [SqM2, SqH],
    [SqM1, SqM2],
    [SqM2, SqM2],
  ]
}

const TSpecs: ModelSpecs = {
  ...partialSQSpecs,
  iconPos: [
    [SqH, SqH]
  ],
  defaultIcons: [
    "v1:03-clima/012"
  ],
  noLeds: true,
}

export const TLDigitsPos: Coords4 = [20, 8, 45, 37];
export const TLHDigitsPos: Coords4 = [45, 25, 45, 37];

const TLSpecs: ModelSpecs = {
  ...partialSQSpecs,
  iconPos: [
    [SqM1, 50.5],
    [SqM2, 50.5],
    [SqM1, 65],
    [SqH, 65],
    [SqM2, 65],
  ],
  iconSize: 6,
  extra: "tl",
  noLeds: false,
  centerTop: true,

  iconExplanations: MODE === "bes" ? undefined : [
    () => t`Heat mode`,
    () => t`Temperature up`,
    () => t`Cool mode`,
    () => t`Switch on/off`,
    () => t`Temperature down`,
  ],

  defaultIcons: MODE === "bes" ? undefined : [
    "v1:03-clima/038",
    "v1:04-flechas/022",
    "v1:03-clima/039",
    "v1:04-flechas/001",
    "v1:04-flechas/027",
  ],
}

/**
 * MODELO V
 * Dimensiones: 129mm x 88mm
 * Dimensiones con sangrado 1mm: 131mm x 90mm
 * PPI: 144 - 5.669291 pixels/mm
 *
 * Resolución 743 x 510
 */
//MEDIDAS HORIZONTAL
export const VWidth = 88 + DIM_MARGIN;
export const VHeight = 129 + DIM_MARGIN;
export const VAspect = VWidth / VHeight;
const VLogoCoords: Coords3 = [0.4 * VWidth, 4, 20];

const partialVSpecs: PartialModelSpecs = {
  dimensions: [VWidth, VHeight],
  defaultLogoCoords: VLogoCoords,
  iconSize: 12,
}


const partialHSpecs: PartialModelSpecs = {
  dimensions: [VHeight, VWidth],
  defaultLogoCoords: [0.4 * VHeight, 4, 20],
  iconSize: 12,
}


const VHY = VHeight / 2; // Half size vertical
const VM1 = 25; // Margin
const VM2 = VWidth - VM1; // Size - margin

const VM1Y6 = 33.5; // Margin vertical V6
const VM2Y6 = VHeight - VM1Y6; // Size - margin vertical V8

const VM1Y8 = 26.5; // Margin vertical V8
const VM2Y8 = VHeight - VM1Y8; // Size - margin vertical V8

const VP1Y8 = (VM2Y8 - VM1Y8) / 3 + VM1Y8; // Middle point 1
const VP2Y8 = 2 * (VM2Y8 - VM1Y8) / 3 + VM1Y8; // Middle point 2

const V6Specs: ModelSpecs = {
  ...partialVSpecs,
  iconPos: [
    [VM1, VM1Y6],
    [VM2, VM1Y6],
    [VM1, VHY],
    [VM2, VHY],
    [VM1, VM2Y6],
    [VM2, VM2Y6],
  ]
}

const V8Specs: ModelSpecs = {
  ...partialVSpecs,
  iconPos: [
    [VM1, VM1Y8],
    [VM2, VM1Y8],
    [VM1, VP1Y8],
    [VM2, VP1Y8],
    [VM1, VP2Y8],
    [VM2, VP2Y8],
    [VM1, VM2Y8],
    [VM2, VM2Y8],
  ]
}

const TRMDScreenHeight = 33;
const TRMDScreenWidth = 44;
export const TRMDScreenPos: Coords4 = [(VWidth - TRMDScreenWidth) / 2, 20, TRMDScreenWidth, TRMDScreenHeight];

const TRMX1 = 32.5; // Margin horizontal TR
const TRMX2 = VWidth - TRMX1; // Size - Margin horizontal TR

const TRMY1 = 69; // Margin vertical TR 1
const TRMY4 = 105; //  Margin vertical TR 4

const TRMY2 = (TRMY4 - TRMY1) / 3 + TRMY1;
const TRMY3 = 2 * (TRMY4 - TRMY1) / 3 + TRMY1;

const TRMDSpecs: ModelSpecs = {
  ...partialVSpecs,
  iconPos: [
    [TRMX1, TRMY1],
    [TRMX2, TRMY1],
    [TRMX1, TRMY2],
    [TRMX2, TRMY2],
    [TRMX1, TRMY3],
    [TRMX2, TRMY3],
    [TRMX1, TRMY4],
    [TRMX2, TRMY4],
  ],

  iconSize: 7,
  extra: "trmd",
  noLeds: false,
}

//Variables para modelo horizontal.
const HHeight = VWidth;
const HWidht = VHeight;
const centerX = HWidht / 2;
const centerY = HHeight/2;

export const VHNumberPos: Coords4 = [45, 43, 52, 17];
export const VHHNumberPos: Coords4 = [centerX, centerY, 52, 17];

const VHSpecs: ModelSpecs = {
  ...partialVSpecs,
  iconPos: [
    [45, 70],
    [22, 105],
    [68, 105],
  ],

  defaultIcons: [
    "v1:05-hotel/004",
    "v1:05-hotel/013",
    "v1:05-hotel/037"
  ],

  iconExplanations: [
    () => t`Bell`,
    () => t`DND (Do Not Disturb)`,
    () => t`MUR (Make Up Room)`
  ],

  defaultIconBorders: [
    true,
    false,
    false
  ],

  iconSize: 12,
  extra: "vh",
  noLeds: false,
}

const VHDSpecs: ModelSpecs = {
  ...VHSpecs,

  extra: "vhd"
}

export const TLVDigitsPos: Coords4 = [20, 10, 50, 37];

const TLVM1 = 54;
const TLVM4 = 99;
const TLVM2 = (TLVM4 - TLVM1) / 3 + TLVM1; // 69
const TLVM3 = 2 * (TLVM4 - TLVM1) / 3 + TLVM1; //84

const TLVSpecs: ModelSpecs = {
  ...partialVSpecs,
  iconPos: [
    [VM1, TLVM1],
    [VM2, TLVM1],
    [VM1, TLVM2],
    [VM2, TLVM2],
    [VM1, TLVM3],
    [VM2, TLVM3],
    [VM1, TLVM4],
    [VM2, TLVM4],
  ],
  iconSize: 6,
  extra: "tlv",
  noLeds: false,
  centerTop: true,

  iconExplanations: MODE === "bes" ? undefined : [
    () => t`Heat mode`,
    () => t`Temperature up`,
    () => t`Cool mode`,
    () => t`Temperature down`
  ],

  defaultIcons: MODE === "bes" ? undefined : [
    "v1:03-clima/038",
    "v1:04-flechas/022",
    "v1:03-clima/039",
    "v1:04-flechas/027",
  ],
}

export const TLRVDigitsPos: Coords4 = [11.6, 12.6, 68.5, 48.5];
const TLRVM1 = 70;
const TLRVM4 = 103;
const TLRVM2 = (TLRVM4 - TLRVM1) / 3 + TLRVM1;
const TLRVM3 = 2 * (TLRVM4 - TLRVM1) / 3 + TLRVM1;

const TLRVSpecs: ModelSpecs = {
  ...partialVSpecs,
  iconPos: [
    [VWidth / 2 + 0.25, 59],

    [VM1 + 0.25, TLRVM1],
    [VM2 + 0.25, TLRVM1],
    [VM1 + 0.25, TLRVM2],
    [VM2 + 0.25, TLRVM2],
    [VM1 + 0.25, TLRVM3],
    [VM2 + 0.25, TLRVM3],
    [VM1 + 0.25, TLRVM4],
    [VM2 + 0.25, TLRVM4],
  ],

  iconExplanations: [
    () => t`On/Off`
  ],

  iconSize: 6,
  extra: "tlrv",
  noLeds: false,
  centerTop: true
}


 
const H8Specs: ModelSpecs = {
  ...partialHSpecs,
  iconPos: [
      [centerX - 39.14, centerY + 20.08],
      [centerX - 39.14,  centerY - 20.08],
      [centerX - 13.4, centerY + 20.08],
      [centerX - 13.4,  centerY - 20.08],
      [centerX + 13.4,  centerY + 20.08],
      [centerX + 13.4,  centerY - 20.08],   
      [centerX + 39.14, centerY + 20.08],
      [centerX + 39.14,  centerY - 20.08],
  ]  

}

const H6Specs: ModelSpecs = {
  ...partialHSpecs,
  iconPos: [
      [centerX - 32.13, centerY + 20.08],
      [centerX - 32.13, centerY - 20.08],
      [centerX, centerY + 20.08],
      [centerX, centerY - 20.08],
      [centerX + 32.13, centerY + 20.08],
      [centerX + 32.13, centerY - 20.08],

  ]

}

const H4Specs: ModelSpecs = {
  ...partialHSpecs,
  iconPos: [
    [centerX - 25.87, centerY + 20.08],
    [centerX - 25.87, centerY - 20.08],
    [centerX + 25.87, centerY + 20.08],
    [centerX + 25.87, centerY - 20.08],   
 
  ]

}


const H2Specs: ModelSpecs = {
  ...partialHSpecs,
  iconPos: [
    [centerX - 25.87, centerY],
    [centerX + 25.87, centerY],
  ]

}

const HHSpecs: ModelSpecs = {
  ...partialHSpecs,
  iconPos: [
    [65.5, centerY + 19.87], //campana
    [centerX - 20.75, centerY + 19.87], //escoba
    [centerX + 20.75, centerY + 19.87], //puerta
  ],

  defaultIcons: [
    "v1:05-hotel/004", 
    "v1:05-hotel/037",
    "v1:05-hotel/013"
  ],

  iconExplanations: [
    () => t`Bell`,
    () => t`DND (Do Not Disturb)`,
    () => t`MUR (Make Up Room)`
  ],

  defaultIconBorders: [
    false,
    false,
    false
  ],

  iconSize: 8,
  extra: "vh",
  noLeds: false,
}

const TLHSpecs: ModelSpecs = {
  ...partialHSpecs,
  iconPos: [
    [centerX - 39.91, centerY + 8.66],
    [centerX - 39.91, centerY - 9.41], 
    [centerX, centerY + 20.63], 
    [centerX + 39.91, centerY + 8.66], 
    [centerX + 39.91, centerY - 9.41],

  ],
  iconSize: 6,
  extra: "tl",
  noLeds: false,
  centerTop: true,
  
  iconExplanations: [
    () => t`Cool mode`,
    () => t`Heat mode`,
    () => t`Switch on/off`,
    () => t`Temperature down`,
    () => t`Temperature up`

  ],

  defaultIcons: [
    "v1:03-clima/039",
    "v1:03-clima/038",
    "v1:04-flechas/001",
    "v1:04-flechas/027",
    "v1:04-flechas/022"

  ],
}


export type Color = "black" | "white";
export const Colors = ["black", "white"];

export type Option = "basic" | "design" | "capriccio" | "onlyGlass";

export type SpecsMapType = {
  [model: string]: ModelSpecs & { code: string, name: string, color: Color, option: Option, availableOptions: (string | null)[] };
};

const black: Color = "black";
const white: Color = "white";

const allOptions: Option[] = [
  "basic",
  "design",
  "capriccio",
  "onlyGlass"
];

// Parameters: [codes, baseSpecs, friendlyName, color][]
// Each code represents one of the options, excluding "onlyGlass", to exclude one use null
function s(elems: [(string | null)[], ModelSpecs, string, Color][]): SpecsMapType {
  const map: SpecsMapType = {};

  elems.forEach(([codes, baseSpec, name, color]) => {
    codes[0] = null; // TODO: Skipping basic models, we might wanna show them?
    codes.forEach((code, i) => {
      if (code) {
        map[code] = { code, ...baseSpec, name, color, option: allOptions[i], availableOptions: codes, };
      }
    });
  });

  return map;
}


const SpecsMapIng: SpecsMapType = s([
  // SQ Básico
  [["CB-SQ2B", "CD-SQ2B", "CC-SQ2B"], Sq2Specs, "Cubic-SQ2", white],
  [["CB-SQ2N", "CD-SQ2N", "CC-SQ2N"], Sq2Specs, "Cubic-SQ2", black],

  [["CB-SQ4B", "CD-SQ4B", "CC-SQ4B"], Sq4Specs, "Cubic-SQ4", white],
  [["CB-SQ4N", "CD-SQ4N", "CC-SQ4N"], Sq4Specs, "Cubic-SQ4", black],

  [["CB-SQ6B", "CD-SQ6B", "CC-SQ6B"], Sq6Specs, "Cubic-SQ6", white],
  [["CB-SQ6N", "CD-SQ6N", "CC-SQ6N"], Sq6Specs, "Cubic-SQ6", black],

  // V Básico
  [["CB-V6B", "CD-V6B", "CC-V6B"], V6Specs, "Cubic-V6", white],
  [["CB-V6N", "CD-V6N", "CC-V6N"], V6Specs, "Cubic-V6", black],

  [["CB-V8B", "CD-V8B", "CC-V8B"], V8Specs, "Cubic-V8", white],
  [["CB-V8N", "CD-V8N", "CC-V8N"], V8Specs, "Cubic-V8", black],

  // TRMD
  [["CB-TRMDB", "CD-TRMDB", null], TRMDSpecs, "TRMD", white],
  [["CB-TRMDN", "CD-TRMDN", null], TRMDSpecs, "TRMD", black],

  // T
  [["CB-TB", "CD-TB", "CC-TB"], TSpecs, "Cubic-T", white],
  [["CB-TN", "CD-TN", "CC-TN"], TSpecs, "Cubic-T", black],

  //[["CB-THB", "CD-THB", "CC-THB"], TSpecs, "Cubic-TH", white],
  //[["CB-THN", "CD-THN", "CC-THN"], TSpecs, "Cubic-TH", black],

  [["CB-TLB", "CD-TLB", "CC-TLB"], TLSpecs, "Cubic-TL", white],
  [["CB-TLN", "CD-TLN", "CC-TLN"], TLSpecs, "Cubic-TL", black],

  [["CB-TLVB", "CD-TLVB", "CC-TLVB"], TLVSpecs, "Cubic-TLV", white],
  [["CB-TLVN", "CD-TLVN", "CC-TLVN"], TLVSpecs, "Cubic-TLV", black],

  /// VH
  [[null, "CD-VHB", null], VHSpecs, "Cubic-VH", white],
  [[null, "CD-VHN", null], VHSpecs, "Cubic-VH", black],

  // TLRV
  [["CB-TLRVB", "CD-TLRVB", null], TLRVSpecs, "Cubic-TLRV", white],
  [["CB-TLRVN", "CD-TLRVN", null], TLRVSpecs, "Cubic-TLRV", black],
]);

const SpecsMapBes: SpecsMapType = s([
  // SQ Básico
  [["PL112100", "PL212100", "PL312100"], Sq2Specs, "Cubik-SQ2", white],
  [["PL112200", "PL212200", "PL312200"], Sq2Specs, "Cubik-SQ2", black],

  [["PL114100", "PL214100", "PL314100"], Sq4Specs, "Cubik-SQ4", white],
  [["PL114200", "PL214200", "PL314200"], Sq4Specs, "Cubik-SQ4", black],

  [["PL116100", "PL216100", "PL316100"], Sq6Specs, "Cubik-SQ6", white],
  [["PL116200", "PL216200", "PL316200"], Sq6Specs, "Cubik-SQ6", black],

  // V Básico
  [["PL126100", "PL226100", "PL326100"], V6Specs, "Cubik-V6", white],
  [["PL126200", "PL226200", "PL326200"], V6Specs, "Cubik-V6", black],

  [["PL128100", "PL228100", "PL328100"], V8Specs, "Cubik-V8", white],
  [["PL128200", "PL228200", "PL328200"], V8Specs, "Cubik-V8", black],

  // TRMD
  [["DS421100", "DS422100", null], TRMDSpecs, "TRMD-K", white],
  [["DS421200", "DS422200", null], TRMDSpecs, "TRMD-K", black],

  // T
  [["SR591110", "SR592110", "SR593110"], TSpecs, "Cubik-T", white],
  [["SR591120", "SR592120", "SR593120"], TSpecs, "Cubik-T", black],

  [["SR591310", "SR592310", "SR593310"], TSpecs, "Cubik-TH", white],
  [["SR591320", "SR592320", "SR593320"], TSpecs, "Cubik-TH", black],

  [["SR591210", "SR592210", "SR593210"], TLVSpecs, "Cubik-TL", white],
  [["SR591220", "SR592220", "SR593220"], TLVSpecs, "Cubik-TL", black],

  /// VH
  [[null, "PL221100", null], VHSpecs, "Cubik-VH", white],
  [[null, "PL221200", null], VHSpecs, "Cubik-VH", black],

  // VHD
  [["PL122100", "PL222100", null], VHDSpecs, "Cubik-VHD", white],
  [["PL122200", "PL222200", null], VHDSpecs, "Cubik-VHD", black],

  // TLRV
  [["SR591410", "SR592410", null], TLRVSpecs, "Cubik-TLR", white],
  [["SR591420", "SR592420", null], TLRVSpecs, "Cubik-TLR", black],

  [["PL132100", "PL232100", "PL332100"], H2Specs, "Cubik-H2", white],
  [["PL132200", "PL232200", "PL332200"], H2Specs, "Cubik-H2", black],

  [["PL134100", "PL234100", "PL334100"], H4Specs, "Cubik-H4", white],
  [["PL134200", "PL234200", "PL334200"], H4Specs, "Cubik-H4", black],

  [["PL136100", "PL236100", "PL336100"], H6Specs, "Cubik-H6", white],
  [["PL136200", "PL236200", "PL336200"], H6Specs, "Cubik-H6", black],

  [["PL138100", "PL238100", "PL338100"], H8Specs, "Cubik-H8", white],
  [["PL138200", "PL238200", "PL338200"], H8Specs, "Cubik-H8", black],

  [["PL131100", "PL231100", "PL331100"], HHSpecs, "Cubik-HH", white],
  [["PL131200", "PL231200", "PL331200"], HHSpecs, "Cubik-HH", black],

  [["SR591210", "SR592210", "SR593210"], TLHSpecs, "Cubik-TLH", white],
  [["SR591220", "SR592220", "SR593220"], TLHSpecs, "Cubik-TLH", black],

]);

export const SpecsMap: SpecsMapType = MODE === "bes" ? SpecsMapBes : SpecsMapIng;

const firstDesignModel = Object
  .entries(SpecsMap)
  .find(([_, e]) => e.option === "design")![0];

export const DefaultDesign: DesignData = {
  id: "",
  project_id: "",
  name: "",
  bg_opacity: 100,
  logo_opacity: 100,
  room_number_size: 10,
  roomNumberYPos: 0,
  model: firstDesignModel,
  glass_only: false,
  icon_paths: [],
  icon_colors: [],
  icon_sizes: [],
  icon_texts: [],
  icon_texts_colors: [],
  icon_texts_fonts: [],
  icon_texts_sizes: [],
  icon_vert_positions: [],
  icon_hor_positions: [],
  icon_borders: [],
}

export const designModelIndex = (d: DesignData) => {
  return Object.keys(SpecsMap).findIndex(v => v === d.model);
}

for (let [model, data] of Object.entries(SpecsMapIng)) {
  const square = data.dimensions[0] === data.dimensions[1];

  const squareGuess =
    // All the SQ are Squares
    model.indexOf("-SQ") !== -1

    // From the T series, we have to exclude TLV, TLRV and TRMD
    || (model.indexOf("-T") !== -1 && model.indexOf("-TLV") === -1 && model.indexOf("-TLRV") === -1 && model.indexOf("-TRMD") === -1)
    ;

  if (square !== squareGuess) {
    // TODO: Si esto casca, hay que cambiar la logica de arriba y implementarlo en ModelGlassOnlyViewController.php
    throw new Error(`ING: Invalid model square check. Model: ${model} Expected:${square} Got:${squareGuess}`);
  }
}

for (let [model, data] of Object.entries(SpecsMapBes)) {
  const square = data.dimensions[0] === data.dimensions[1];

  const squareGuess =
    model.startsWith("PL21") || model.startsWith("PL31")
    || model.startsWith("SR5921")
    || model.startsWith("SR5923")
    || model.startsWith("SR5931")
    || model.startsWith("SR5933")
    ;

  if (square !== squareGuess) {
    // TODO: Si esto casca, hay que cambiar la logica de arriba y implementarlo en ModelGlassOnlyViewController.php
    throw new Error(`BES: Invalid model square check. Model: ${model} Expected:${square} Got:${squareGuess}`);
  }
}