import isEqual from "react-fast-compare";
import { DefaultDesign, DesignData, UserData, ProjectData, SpecsMap } from "../types";
import React, { Component } from "react";
import { t } from "ttag";
import { toast } from "react-toastify";
import {
  FaAngleLeft, FaEllipsisV, FaSave, FaTimes, FaShareSquare, FaClone, FaSortAmountDown, FaSortAmountUp,
  FaUser, FaHourglassHalf, FaPlusCircle, FaPlusSquare, FaMinusSquare, FaUndo, FaCheckCircle
} from "react-icons/fa";
import { fetchGet, fetchPost, getUsrThumbUrl, handler, setToken, boxShadow, MAX_DESIGNS, shiftTop, loading, blobToDataURL, MODE, isAdminInpersonate, inpersonateGoBack, parseJson, isMobile, isLandscapeDesign } from "../util";
import AccountInfoComponent from "./AccountInfo";
import EditPageComponent from "./EditPage";
import OrderComponent from "./Order";
import { Modal, SelectProject } from "./shared";
import { ReactComponent as CubLogo } from "../resources/logo_cub.svg";
import { ReactComponent as CubLogoShort } from "../resources/logo_cub_short.svg";
import ModelSVGComponent from "./ModelSVG";

type MainPageProps = {
  user: UserData,
  updateHandler: (register?: boolean) => void,
}

enum ProjectSortField {
  created_at, updated_at, status, name
}

type MainPageState = {
  editing?: DesignData,
  projects: ProjectData[],
  loading: boolean,

  orderProject?: ProjectData,

  designToClone?: DesignData,
  targetProject?: string,

  expandedProject?: string,

  projectSortField: ProjectSortField,
  projectSortAscending: boolean,

  // PDF Export
  downloadDesign?: DesignData,
  downloadFinished?: (svg: Blob | string) => void,
}

const ORDER_BUTTON_ID = "orderModalButton";
const PROJECT_BUTTON_ID = "projectModalButton";

const PROJECT_SORT_COOKIE = "projectSort"
const PROJECT_SORT_ASC_COOKIE = "projectSortAsc"

export default class MainPageComponent extends Component<MainPageProps, MainPageState> {
  canvasRef = React.createRef<ModelSVGComponent>();
  editorRef = React.createRef<EditPageComponent>();

  constructor(props: MainPageProps) {
    super(props);

    const sort = (window.localStorage.getItem(PROJECT_SORT_COOKIE) ?? "created_at") as keyof typeof ProjectSortField;
    const asc: boolean = window.localStorage.getItem(PROJECT_SORT_ASC_COOKIE) === "true";
    this.state = { projects: [], loading: true, projectSortField: ProjectSortField[sort], projectSortAscending: asc ?? false };
  }

  deleteHandler = (design: DesignData) => {
    const confirm = window.confirm(t`Are you sure you want to delete '${design.name.includes("{{") ? design.name.slice(0, -7) : design.name}'`);

    if (confirm) {
      fetchPost(`/api/design/${design.id}/delete`).then(res => {
        if (!res.ok) {
          toast.error(t`Error deleting design`);
        } else {
        }
        this.reloadProjects();
      });
    }
  }

  cloneHandler = (design: DesignData) => {
    this.setState({ designToClone: design }, () => {
      document.getElementById(PROJECT_BUTTON_ID)!.click();
    });
  }

  editHandler = (design?: DesignData, confirmSave?: boolean) => {
    shiftTop();

    if (!design) {
      const editor = this.editorRef.current;

      if (confirmSave
        && editor
        && !isEqual(this.state.editing, editor.state.design)
        && !window.confirm(t`There are unsaved changes, are you sure you want to exit?`)) {
        return;
      }
      loading(true);
      this.reloadProjects();
    } else {
      this.setState({ expandedProject: design.project_id });
    }
    this.setState({ editing: design });
  }

  logoutHandler = (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    setToken(undefined);
    this.props.updateHandler(false);
  }

  scrollToSelected = () => {
    const elem = document.querySelector(".collapse.show");
    const parent = elem?.parentElement || elem?.parentNode;

    (parent as any)?.scrollIntoView?.();
  }

  reloadProjects = () => {
    this.scrollToSelected();
    fetchGet('/api/project')
      .then(res => {
        if (res.ok) {
          res.json().then(p => this.setState({ projects: p, loading: false }, () => { this.scrollToSelected(); loading(false); }));
        } else {
          toast.error(t`Unknown error`);
          this.logoutHandler();
        }
      });
  }

  componentDidMount() {
    this.reloadProjects();
  }

  render() {
    const sort = {
      field: this.state.projectSortField, setField: (f: ProjectSortField) => {
        window.localStorage.setItem(PROJECT_SORT_COOKIE, ProjectSortField[f])
        this.setState({ projectSortField: f })
      },
      ascending: this.state.projectSortAscending, setAscending: (b: boolean) => {
        window.localStorage.setItem(PROJECT_SORT_ASC_COOKIE, String(b))
        this.setState({ projectSortAscending: b })
      },
    };
    return (
      <div className="h-100vh d-flex flex-column">
        <Navbar editing={this.state.editing} editorRef={this.editorRef} newProject={this.newProject} sort={sort}
          editHandler={this.editHandler} logoutHandler={this.logoutHandler} name={this.props.user.name} />
        <div className="h-100 of-y-auto of-x-hidden scroller">
          <AccountInfoComponent name={this.props.user.name} email={this.props.user.email} />
          {this.content()}
        </div>
      </div>
    );
  }

  newProject = () => {
    const name = prompt(t`Input the new project name`);
    if (!name) {
      return;
    }

    if (this.state.projects.find(p => p.name === name)) {
      toast.error(t`This name is already in use`);
      return;
    }

    const data = JSON.stringify({ name });
    fetchPost("/api/project", data)
      .then(_ => this.reloadProjects());
  }

  exportProject = async (project: ProjectData) => {
    const images: { design: DesignData, img: Blob | string }[] = [];

    loading(true);

    const { default: jsPDF } = await import("jspdf");
    const doc = new jsPDF('p', 'mm', 'a4');

    for (let i in project.designs) {
      const design = project.designs[i];

      const url = getUsrThumbUrl(design.id, design.updated_at || "");

      const res = await fetch(url);
      const blob = await res.blob();

      const dataUrl = await blobToDataURL(blob);
      const specs = SpecsMap[design.model];
      const [w, h] = specs.dimensions;
      const isLandscape = isLandscapeDesign(design)

      //header
      doc.setFontSize(14);
      doc.setDrawColor("#000")
      doc.text(MODE === "bes" ? "CUBIK by Ingenium" : "CUBIC by Ingenium", 20, 20);

      doc.setFontSize(10);
      doc.setDrawColor("#333")
      doc.text(project.name + (project.order_code ? " (" + project.order_code + ")" : ""), 190, 20, { align: "right" });

      doc.setLineWidth(0.2);
      doc.setDrawColor("#888")
      doc.line(20, 22, 190, 22);

      // Footer
      doc.setFontSize(10);
      doc.setDrawColor("#000")
      doc.text('Page ' + (+i + 1) + ' of ' + project.designs.length, 105, 285, { align: "center", baseline: "bottom" });

      doc.setLineWidth(0.2);
      doc.setDrawColor("#888")
      doc.line(20, 280, 190, 280);
      // ----------
      
      doc.setDrawColor("#000")
      doc.setFillColor("#888");
      const margin = 0.4;
      doc.rect( 30 - margin,  (isLandscape ? 80 : 30) - margin, w + margin * 2, h + margin * 2, "FD");
      doc.addImage(dataUrl, "PNG", 30, (isLandscape ? 80 : 30), w, h, undefined, "FAST" as any);
      
   
      doc.setDrawColor("#000")
      doc.setFontSize(16);
      doc.text(design.name.includes("{{") ? design.name.slice(0,-7) : design.name, (isLandscape ? 30 : 130), (isLandscape ? 30 : 40), { maxWidth: 60 });
      doc.setFontSize(10);
      var comelitID = design.name.split("{{")[1];
      doc.text(`${specs.name} (Ref: ${comelitID}${design.model})`, (isLandscape ? 30 : 130), (isLandscape ? 45 : 55));

      if (specs.extra === "vh") {
        const numbers = parseJson(design.order_extra).numbers;
        const numbersSplit = numbers?.split(",") || ["None"];
        let toPrint = "";

        let yPos = 60;
        let yPosSkip = 5;
        const last = numbersSplit.length - 1;

        doc.setFontSize(10);
        doc.text(t`Room numbers` + ": (" + parseJson(design.order_extra).prefix + ")", (isLandscape ? 30 : 130),  (isLandscape ? yPos-5 : yPos));

        for (let i = 0; i < numbersSplit.length; i++) {
          toPrint += numbersSplit[i]

          if (i !== last) {
            toPrint += ", "
          }

          if (toPrint.length >= 25 || i === last) {
            yPos += yPosSkip;
            doc.text(toPrint.trim(), (isLandscape ? 30 : 130), yPos);
            toPrint = "";
          }
        }
      }

      +i < project.designs.length - 1 && doc.addPage();
    }

    loading(false);
    doc.save(project.name + ".pdf");
    return;
  }

  renameProject = (id: string) => {
    const name = prompt(t`Input the new project name`);
    if (!name) {
      return;
    }

    if (this.state.projects.find(p => p.name === name)) {
      toast.error(t`This name is already in use`);
      return;
    }

    const data = JSON.stringify({ name });
    fetchPost(`/api/project/${id}/rename`, data)
      .then(_ => this.reloadProjects());
  }

  deleteProject = (id: string, name: string) => {
    const confirm = window.confirm(t`Project ${name} and it's contents are going to be deleted, continue?`);

    if (confirm) {
      fetchPost(`/api/project/${id}/delete`)
        .then(_ => this.reloadProjects());
    }
  }

  duplicateProject = (id: string) => {
    const name = prompt(t`Input the new project name`);
    if (!name) {
      return;
    }

    if (this.state.projects.find(p => p.name === name)) {
      toast.error(t`This name is already in use`);
      return;
    }

    const data = JSON.stringify({ name });
    fetchPost(`/api/project/${id}/duplicate`, data)
      .then(_ => this.reloadProjects());
  }

  prepareOrder = (id: string) => {
    this.setState(
      () => ({ orderProject: this.state.projects.find(p => p.id === id) }),
      () => document.getElementById(ORDER_BUTTON_ID)!.click()
    );
  }

  projectSort = (a: ProjectData, b: ProjectData) => {
    let res = 0;

    switch (this.state.projectSortField) {
      case ProjectSortField.created_at:
        res = Date.parse(b.created_at!) - Date.parse(a.created_at!);
        break;

      case ProjectSortField.updated_at:
        res = Date.parse(b.updated_at!) - Date.parse(a.updated_at!);
        break;

      case ProjectSortField.status:
        res = b.status.localeCompare(a.status);
        break;

      case ProjectSortField.name:
        res = b.name.localeCompare(a.name);
        break;
    }

    if (this.state.projectSortAscending) { res *= -1; }

    return res;
  };

  content = () => {
    const { editing, projects, expandedProject, designToClone, orderProject, downloadDesign, downloadFinished } = this.state;

    const projectOptions = projects.map(p => ({ label: p.name, value: p.id, enabled: p.status === "created" }));

    if (editing) {
      return <EditPageComponent ref={this.editorRef} editing={editing} projects={projectOptions} />;
    } else if (projects.length <= 0) {
      return (
        <div className="d-flex align-items-center">
          <p className="mx-auto mt-5 text-light">{
            this.state.loading
              ? t`Loading projects...`
              : t`No projects saved, please create a new one by clicking 'New project'`
          }</p>
        </div>
      );
    } else {
      const numDesigns = projects.reduce((prev, curr) => prev + curr.designs.length, 0);

      return <>
        <Modal btnId={PROJECT_BUTTON_ID} title={t`Create or select the project`} height={400} footer={<div>
          <button type="button" className="btn btn-primary"
            onClick={() => {
              const pid = this.state.targetProject;

              fetchPost(`/api/design/${designToClone!.id}/clone/${pid}`).then(res => {
                if (!res.ok) {
                  toast.error(t`Error cloning design`);
                }
                this.reloadProjects();
              });

              document.getElementById(PROJECT_BUTTON_ID)!.click();

            }}>{t`Save`}</button>
        </div>}>
          <SelectProject options={projectOptions}
            onSelected={p => this.setState({ targetProject: p ? p.value : undefined })}
            onCreated={() => null} />
        </Modal>

        {/* The order modal */}

        {orderProject && <OrderComponent buttonId={ORDER_BUTTON_ID}
          project={orderProject} onOrderPlaced={this.reloadProjects} />}

        <div className="row justify-content-center m-0">
          <div id="designsList" className="accordion pt-2 col-12 col-lg-11 col-xl-9">
            {projects.sort(this.projectSort).map((p, i) => {
              const empty = !p.designs || p.designs.length <= 0;
              const orderInProcess = p.status !== "created";
              const canEdit = !orderInProcess || isAdminInpersonate();


              const isExpanded = (!expandedProject && i === 0) || (expandedProject && p.id === expandedProject);

              return (
                <div key={p.id} className="card m-2 bg-dark" style={{ overflow: "visible", boxShadow }}>
                  <div id={`heading-${p.id}`} className="card-header d-flex">
                    <div className="stretch-contain d-flex flex-grow-1" style={{ minWidth: 0 }}>
                      <button className={`d-block btn btn-link ${isExpanded ? "" : "collapsed"} text-light text-decoration-none stretched-link text-ellipsis`}
                        type="button" data-toggle="collapse" data-target={`#collapse-${p.id}`}>
                        <FaPlusSquare className="plus-collapse" style={{ verticalAlign: -2.5 }} />
                        <FaMinusSquare className="minus-collapse" style={{ verticalAlign: -2.5 }} />
                        <span className="pl-3">{p.name || t`Unnamed project`}</span>
                      </button>
                    </div>

                    <div className="dropdown d-flex flex-shrink-0">
                      {orderInProcess && <span className="py-1 mx-2">
                        {/* TODO: We can also use FaHourglassStart & FaHourglassEnd to represent the different phases */}
                        {p.status === "done" ?
                          <FaCheckCircle style={{ verticalAlign: -3.5, color: "white" }} />
                          :
                          <FaHourglassHalf style={{ verticalAlign: -3.5, color: "white" }} />
                        }
                        <small className="text-light ml-2">
                          {
                            p.status === "waiting-validate" ?
                              t`Validating order` :
                              p.status === "waiting-printing" ?
                                t`Printing order` :
                                p.status === "done" ?
                                  t`Order complete` :
                                  t`Unknown status` + ` (${p.status})`
                          }
                        </small>
                      </span>}
                      {!orderInProcess && !empty && <a href="#n" role="button" className="py-1 mx-2 text-light text-decoration-none"
                        title={t`Send for printing`}
                        onClick={handler(this.prepareOrder, p.id)}>
                        <FaShareSquare style={{ verticalAlign: -3.5 }} />
                        <small className="hide-mobile pl-2">{t`Place order`}</small>
                      </a>}
                      <a href="#d" role="button" data-toggle="dropdown" className="py-1 px-2 text-light">
                        <FaEllipsisV />
                      </a>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item" href="#r"
                          onClick={handler(this.exportProject, p)}>{t`Export project (.PDF)`}</a>
                        <a className="dropdown-item" href="#r"
                          onClick={handler(this.renameProject, p.id)}>{t`Rename project`}</a>
                        <a className="dropdown-item" href="#d"
                          onClick={handler(this.duplicateProject, p.id)}>{t`Duplicate project`}</a>
                        {!orderInProcess && <a className="dropdown-item" href="#e"
                          onClick={handler(this.deleteProject, p.id, p.name)}>{t`Delete project`}</a>}
                      </div>
                    </div>
                  </div>
                  <div id={`collapse-${p.id}`} data-parent="#designsList"
                    className={`collapse ${isExpanded ? "show" : ""}`}>
                    <div className="card-body d-flex flex-wrap justify-content-center-mobile">
                      {p.designs?.sort((a, b) => Date.parse(a.created_at!) - Date.parse(b.created_at!))
                        .map(p => {
                          return (
                            <Card design={p} key={p.id}
                              cloneHandler={this.cloneHandler}
                              editHandler={canEdit ? this.editHandler : undefined}
                              deleteHandler={canEdit ? this.deleteHandler : undefined} />
                          );
                        })}

                      {!orderInProcess && <CardNew editHandler={this.editHandler} projectId={p.id} numDesigns={numDesigns} user={this.props.user} />}
                    </div>
                  </div>
                </div>
              );
            })}
            <div id="bottom-bar-spacer" />
          </div>
        </div>

        <ModelSVGComponent ref={this.canvasRef} format={"png"} pngWidth={500}
          justReturn={true} height={0} design={downloadDesign}
          downloadFinished={svg => this.setState({ downloadDesign: undefined },
            () => downloadFinished?.(svg))} />
      </>;
    }
  }
}

type CardProps = {
  design: DesignData,
  cloneHandler: (p: DesignData) => void,
  editHandler?: (p: DesignData) => void,
  deleteHandler?: (p: DesignData) => void,
}

function Card(props: CardProps) {
  const { design, cloneHandler, editHandler, deleteHandler } = props;
  const { id, name, updated_at } = design;

  const cardTitle = editHandler ? t`Edit ${name.includes("{{") ? name.slice(0,-7) : name}` : t`Can't edit already started order`;
  const opacity = editHandler ? 1 : 0.6;
  const cursor = editHandler ? "pointer" : "";

  const pointerEvents = editHandler ? "inherit" : "none";
  
  const isLandscape = isLandscapeDesign(design);
  
  return (
    <div className="card hover-primary text-white" onClick={handler(editHandler, design)} title={cardTitle}
      style={{ width: 200, height: 390, margin: 10, cursor, opacity, pointerEvents, backgroundColor: "rgba(0,0,0,0.2)" }}>
      <button className="btn btn-primary btn-circle hover-bg-white" title={t`Clone`}
        onClick={handler(cloneHandler, design)} style={{ position: "absolute", top: 4, left: 4, boxShadow, padding: 6 }}>
        <FaClone className="text-dark" fontSize={15} />
      </button>
      {deleteHandler && <button className="btn btn-primary btn-circle hover-bg-white" title={t`Delete`}
        onClick={handler(deleteHandler, design)} style={{ position: "absolute", top: 4, right: 4, boxShadow, padding: 5  }}>
        <FaTimes className="text-dark" fontSize={18} />
      </button>}
      <div className="d-flex rounded-top w-100 p-2" style={{ height: 350}} >
        {isLandscape &&  <img src={getUsrThumbUrl(id, updated_at || "")} width="180px" decoding="async" loading="lazy"
          className="m-auto" alt="..." style={{ boxShadow, borderRadius: 5, border: "1px solid gray"}} />
        }
        {!isLandscape &&  <img src={getUsrThumbUrl(id, updated_at || "")} height="180px" decoding="async" loading="lazy"
          className="m-auto" alt="..." style={{ boxShadow, borderRadius: 5, border: "1px solid gray"}} />
        }
       </div>
      <div className="card-body p-1"> 
        <p className="card-text text-center text-ellipsis">{name.includes("{{") ? name.slice(0,-7) : name}</p>
      </div>
    </div>
  );
}

function CardNew(props: { numDesigns: number, projectId: string, editHandler: (p: DesignData) => void, user: UserData }) {
  const { numDesigns, projectId, editHandler, user } = props;

  const createNew = () => {
    const email = user.email.trim().toLowerCase()
    if (!email.endsWith("@ingeniumsl.com") && !email.endsWith("@besknx.com") && numDesigns >= MAX_DESIGNS) {
      toast.error(t`You've reached the max number of designs for your account, please delete some to open some space`);
      return;
    }

    editHandler({ ...DefaultDesign, project_id: projectId });
  }

  return (
    <div className="card hover-primary text-white" onClick={handler(createNew)} title={t`New design`}
      style={{ width: 200, height: 390, margin: 10, cursor: "pointer", backgroundColor: "rgba(0,0,0,0.2)" }}>
      <div className="d-flex rounded-top w-100 p-2" style={{ height: 350 }} >
        <FaPlusCircle style={{ width: "30%", height: "auto", margin: "auto", boxShadow, borderRadius: 500 }} />
      </div>
      <div className="card-body p-1">
        <p className="card-text text-center text-ellipsis">{t`New design`}</p>
      </div>
    </div>
  );
}

function Navbar(props: any) {
  return (
    <nav className="navbar sticky-top navbar-expand navbar-dark bg-dark" style={{ boxShadow }}>
      <NavbarContent {...props} />

      {isAdminInpersonate() && <div className="nav-item">
        <a className="nav-link small-mobile text-light" href="#adm"
          onClick={() => { inpersonateGoBack(); window.location.reload(); }} >
          <FaUndo style={{ verticalAlign: -2.5 }} />
          <span className="pl-2">{t`Back to Admin`}</span>
        </a>
      </div>}

      <div className="nav-item dropdown">
        <a className="nav-link dropdown-toggle small-mobile text-light" href="#drop"
          id="navbarDropdown" role="button" data-toggle="dropdown">
          <FaUser style={{ verticalAlign: -2.5 }} />
          <span className="pl-2">{props.name}</span>
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <a className="dropdown-item" href="#a1" data-toggle="modal" data-target={"#accountModal"}>{t`My account`}</a>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item" href="#a2" onClick={props.logoutHandler}>{t`Log out`}</a>
        </div>
      </div>
    </nav>
  );
}

function NavbarContent(props: any) {
  const { editHandler, editorRef, editing, newProject, sort } = props;

  if (editing) {
    return <>
      <a className="navbar-brand text-light" href="#pro" title="Volver atrás"
        onClick={handler(p => editHandler(p, true))}>
        <FaAngleLeft className="mb-1 mr-1" />
        {t`Editor`}
      </a>
      <div className="navbar-nav mr-auto">
        <a className="nav-item nav-link active ml-5 text-light small-mobile" href="#link"
          onClick={handler(() => editorRef.current.saveDesign(editHandler))}>
          <FaSave style={{ verticalAlign: -2.5 }} />
          <span className="pl-2">{t`Save`}</span>
        </a>
      </div>
    </>;
  } else {
    return <>
      <a href="#pro" className="hover-opacity" style={{ height: 44, outline: "none" }}
        onClick={handler(editHandler)}>
        <CubLogo id="logo-full" height="100%" />
        <CubLogoShort id="logo-short" height="100%" />
      </a>
      <ul className="navbar-nav m-auto">
        <li className="nav-item">
          <a className="nav-link text-light text-ellipsis small-mobile" href="#link"
            onClick={handler(newProject)}>
            <FaPlusCircle className="text-primary" style={{ fontSize: 28, verticalAlign: -8 }} />
            <span className="ml-2">{t`New project`}</span>
          </a>
        </li>
        <li>
          <div className="nav-item dropdown ml-2">
            <a className="nav-link dropdown-toggle small-mobile text-light" href="#drop"
              id="sortDropdown" role="button" data-toggle="dropdown" style={{ marginTop: 2.4 }}>
              {sort.ascending ? <FaSortAmountUp style={{ verticalAlign: -2.5 }} />
                : <FaSortAmountDown style={{ verticalAlign: -2.5 }} />}
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              {[{ label: t`Creation date`, value: ProjectSortField.created_at },
              { label: t`Last updated`, value: ProjectSortField.updated_at },
              { label: t`Status`, value: ProjectSortField.status },
              { label: t`Name`, value: ProjectSortField.name },].map(({ label, value }, i) =>
                <a key={i} className="dropdown-item" href="#c">
                  <div className="custom-control custom-radio">
                    <input type="radio" className="custom-control-input" id={`checkSort${i}`} checked={sort.field === value} onChange={e => sort.setField(value)} />
                    <label className="w-100 custom-control-label" htmlFor={`checkSort${i}`}>{label}</label>
                  </div>
                </a>
              )}

              <div className="dropdown-divider"></div>

              <a className="dropdown-item" href="#a">
                <div className="custom-control custom-radio">
                  <input type="radio" className="custom-control-input" id="checkSortAsc" checked={sort.ascending} onChange={e => sort.setAscending(true)} />
                  <label className="w-100 custom-control-label" htmlFor="checkSortAsc"> {t`Ascending`}</label>
                </div>
              </a>
              <a className="dropdown-item" href="#d">
                <div className="custom-control custom-radio">
                  <input type="radio" className="custom-control-input" id="checkSortDesc" checked={!sort.ascending} onChange={e => sort.setAscending(false)} />
                  <label className="w-100 custom-control-label" htmlFor="checkSortDesc"> {t`Descending`}</label>
                </div>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </>;
  }
}
